import * as React from "react";
import { Link, graphql } from "gatsby";

import HelpIndex from "../components/helpIndex";

const UpsoldHelp = ({ data, location }) => {
  return (
    <HelpIndex
      data={data}
      location={location}
      title="Upsold Help"
      faqUrl="/upsold-faq"
      breadCrumbs={[{ href: "/support-center", label: "Support Home" }, { label: "Upsold Support" }]}
    />
  );
};

export default UpsoldHelp;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/upsold-help.*index.md/" } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
