import * as React from "react";
import { Link } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import BreadCrumbs from "./breadCrumbs";

const HelpIndex = ({ data, location, title, faqUrl, breadCrumbs }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title={title} />
        <Bio />
        <p>No help content found.).</p>
      </Layout>
    );
  }
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={title} />
      <div className="flex flex-col items-center mt-10">
        <div className="max-w-xl">
          <BreadCrumbs breadCrumbs={breadCrumbs} />
        </div>
      </div>
      <div className="blog px-8 flex flex-col items-center mt-10">
        <div className="max-w-xl">
          <ol style={{ listStyle: `none` }}>
            {posts.map((post) => {
              const title = post.frontmatter.title || post.fields.slug;

              return (
                <li key={post.fields.slug}>
                  <article className="post-list-item" itemScope itemType="http://schema.org/Article">
                    <header>
                      <h2>
                        <Link to={post.fields.slug} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </h2>
                    </header>
                  </article>
                </li>
              );
            })}
            <li>
              <article className="post-list-item" itemScope itemType="http://schema.org/Article">
                <header>
                  <h2>
                    <Link to={faqUrl} itemProp="url">
                      <span itemProp="headline">FAQs</span>
                    </Link>
                  </h2>
                  <small></small>
                </header>
              </article>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  );
};

export default HelpIndex;
